.App {
  text-align: center;
}

.App-logo {
  margin-top: 1em;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #fafafa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 1vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}
li.link-list-item{
  align-items: left;
}
ul.no-bullets {
  list-style-type: none;
  /* Remove bullets */
  padding: 0;
  /* Remove padding */
  margin: 0;
  /* Remove margins */
}